<template>
  <div>
    <header class="set-bg-header" :class="user.role > 0 ? 'bg-primary admin' : null"
      v-loading.fullscreen.lock="fullLoading" element-loading-background="rgba(255, 255, 255, 0.8)">
      <el-row type="flex" align="middle" style="z-index: 1">
        <el-col :span="12">
          <div>
            <router-link :to="
              user != null && user.role == 60
                ? '/dashboard'
                : user != null && user.role == 50
                  ? '/log-system'
                  : user != null && user.recommendStatus <= 1
                    ? referCodeUrl('/result')
                    : user != null
                      ? referCodeUrl('/factor')
                      : '/'
            ">
              <img v-if="user.role > 0" style="width: 96px" src="@/assets/logo-white.svg" alt="terak white logo" />
              <img v-else style="width: 90px" src="@/assets/logo.svg" alt="" />
            </router-link>
          </div>
        </el-col>

        <el-col :span="12" class="text-right is-flex ai-center js-end">
          <div>
            <a class="text-white mg-r-4 is-flex" :href="authServer">กลับไป <span class="underline mg-l-6">หน้าหลัก
                EXIM1</span></a>
          </div>
          <el-dropdown trigger="click">
            <div class="flex-display js-center cr-pointer">
              <div class="box-image">
                <avatar :username="user.name" :rounded="false" :src="`${user.profileImg != 'undefined' && user.profileImg
                  ? imgUrl + user.profileImg
                  : ''
                }`" :size="40">
                </avatar>
              </div>
            </div>
            <el-dropdown-menu slot="dropdown">
              <div class="pd-x-6">
                <el-row type="flex" justify="center" class="mg-y-3">
                  <el-col :span="18" class="is-flex flex-fill js-center">
                    <div>
                      <div class="flex-display js-center">
                        <div class="box-image">
                          <avatar :username="user.name" :rounded="false" :src="`${user.profileImg != 'undefined' && user.profileImg
                            ? imgUrl + user.profileImg
                            : ''
                          }`" :size="60">
                          </avatar>
                        </div>
                      </div>
                    </div>
                    <div class="pd-l-6">
                      <h4 class="mg-b-7 mg-t-0">
                        {{ user.name }} {{ user.surname }}
                      </h4>
                      <p class="text-grey mg-y-7">{{ user.email }}</p>
                    </div>
                  </el-col>
                </el-row>
              </div>
              <div>
                <el-row>
                  <el-col :span="24">
                    <div class="menu-list">
                      <router-link :to="'/admin-edit-profile'" v-if="user.role == 50 || user.role == 60"
                        class="text-dark">
                        แก้ไขข้อมูลส่วนตัว
                      </router-link>
                      <a class="text-dark" target="_blank" :href="`${authServer}/Manage/Index`"
                        v-else>แก้ไขข้อมูลส่วนตัว</a>
                    </div>

                    <div class="menu-list" v-if="user.role == 0">
                      <router-link :to="referCodeUrl('/edit-company')" class="text-dark">
                        แก้ไขข้อมูลบริษัท
                      </router-link>
                    </div>
                    <div class="menu-list">
                      <a :href="authServer + '/Account/ChangePassword'" class="text-dark" target="_blank">
                        แก้ไขรหัสผ่าน
                      </a>
                    </div>

                    <div class="menu-list" v-if="user.role == 0">
                      <router-link :to="'/edit-privacy'" class="text-dark">
                        ข้อกำหนดและเงื่อนไขการใช้บริการของระบบ
                      </router-link>
                    </div>
                    <div class="pd-x-5">
                      <el-divider class="mg-y-7"></el-divider>
                    </div>
                    <div class="menu-list" v-if="user.role == 0 && user.recommendStatus <= 1">
                      <router-link :to="referCodeUrl('/reset-assessment')" class="text-grey">
                        ประเมินความพร้อมใหม่
                      </router-link>
                    </div>

                    <div class="menu-list" v-if="
                      user.role == 0 &&
                      user.recommendStatus == 1 &&
                      complacencyStatus != 2
                    ">
                      <router-link :to="referCodeUrl('/feedback/profile')" class="text-grey">
                        ประเมินความพึงพอใจของระบบ
                      </router-link>
                    </div>
                    <div class="menu-list" v-if="user.role == 50 || user.role == 60">
                      <router-link :to="user.role == 50 ? '/log-system' : '/dashboard'" class="text-grey">
                        เมนูผู้ดูแล
                      </router-link>
                    </div>
                    <div @click="signout()" class="menu-list cr-pointer">
                      <a class="text-grey">ออกจากระบบ</a>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </el-dropdown-menu>
          </el-dropdown>
        </el-col>
      </el-row>
    </header>
    <div>
      <div class="main-slot">
        <slot />
      </div>
    </div>
    <div class="text-center footer">
      <span>© สงวนลิขสิทธิ์ พ.ศ.
        {{ new Date().getFullYear() + 543 }}
        ธนาคารเพื่อการส่งออกและนำเข้าแห่งประเทศไทย</span>
    </div>
    <el-dialog v-if="modalProject" :visible.sync="modalProject" width="50%" @close="$router.push($route.path)">
      <p class="mg-t-0 text-grey">
        <span class="text-dark">ท่านสนใจสมัครเข้าร่วมโครงการ</span>
        {{ project.projectName }}
      </p>
      <p class="text-grey">
        <span class="text-dark">วันที่จัดโครงการ</span>
        {{ project.startJoin | dateTh }}
        <!-- - {{ project.endJoin | dashformate }} -->
      </p>
      <div class="text-center">
        <el-button type="primary" @click="joinRef(project.id)">
          สมัครเข้าร่วมโครงการ</el-button>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="successDialog" v-if="successDialog" @close="$router.push($route.path)" class="text-center"
      width="50%">
      <el-button type="success" style="cursor: unset" class="font-44 mg-y-7 pd-4" circle><i
          class="fas fa-check font-38"></i></el-button>

      <h2>เข้าร่วมโครงการสำเร็จ!</h2>
      <p>
        คุณได้เข้าร่วมโครงการ
        <span class="text-primary">{{ project.projectName }}</span>
        เรียบร้อยแล้ว!
      </p>
    </el-dialog>
    <Cookie :pattern="2" />
    <el-dialog v-if="dialogCode" :visible.sync="dialogCode" center class="expDialog signUp"
      :class="resData.data == 'Is not time yet.' ? 'not-time' : ''">
      <div class="is-flex ai-center mg-b-6">
        <p class="mg-0 mg-r-6 font-weight-bold">ชื่อโครงการ</p>
        <p class="mg-0" style="color: #9e9e9e">
          <span v-if="
            resData.data == 'not found data' ||
            resData.data == 'Refer code not work'
          ">ไม่พบชื่อโครงการ</span>
          <span v-else>
            {{ resData.obj.projectName }}
          </span>
        </p>
      </div>
      <div class="is-flex ai-center">
        <p class="mg-0 mg-r-6 font-weight-bold">
          {{
            resData.data == "Refer code time out."
              ? "วันที่จัดโครงการ"
              : "วันที่เปิดรับสมัคร"
          }}
        </p>
        <p class="mg-0" style="color: #9e9e9e">
          <span v-if="
            resData.data == 'not found data' ||
            resData.data == 'Refer code not work'
          ">ไม่พบวันที่เปิดรับสมัคร"</span>
          <span v-else>
            {{
              resData.data == "Refer code time out."
                ? moment(resData.obj.endJoin)
                  .add(543, "year")
                  .format("D MMM yy")
                : moment(resData.obj.startDate)
                  .add(543, "year")
                  .format("D MMM yy") +
                " - " +
                moment(resData.obj.endDate)
                  .add(543, "year")
                  .format("D MMM yy")
            }}
          </span>
        </p>
      </div>
      <div slot="footer" class="text-left">
        <p v-if="resData.data == 'Refer code time out.'" class="cr-pointer" @click="dialogCode = false">
          ปิดรับสมัครโครงการแล้ว
        </p>

        <p v-else-if="
          resData.data == 'not found data' ||
          resData.data == 'Refer code not work'
        " class="cr-pointer" @click="dialogCode = false">
          ลิงก์ลงทะเบียนไม่ถูกต้อง
        </p>
        <p v-if="resData.data == 'Is not time yet.'" class="cr-pointer" @click="dialogCode = false">
          ขณะนี้ยังไม่อยู่ในช่วงเปิดรับสมัครโครงการ
        </p>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Cookie from "@/components/auth/Cookie";
import { HTTP } from "@/service/axios";
import "@/service/Company";
import AuthService from "@/service/oidcService";
import "@/service/Refer";
import Avatar from "vue-avatar";
import { mapState } from "vuex";
export default {
  components: {
    Avatar,
    Cookie
  },
  data ()
  {
    return {
      authServer: process.env.VUE_APP_OIDC_SERVE,
      fullLoading: false,
      dialogCode: false,
      checkComplacency: false,
      imgUrl: process.env.VUE_APP_IMAGE_URL,
      complacencyStatus: "",
      project: null,
      modalProject: false,
      successDialog: false,
      resData: null
    };
  },
  computed: {
    ...mapState( {
      useroidc: state => state.useroidc
    } ),
    user ()
    {
      return this.$store.state.user;
    },
    token ()
    {
      return this.$store.state.token;
    }
  },
  async mounted ()
  {
    if ( this.user.role == 0 )
    {
      this.checkInvalidData();
    }
    if ( this.$store.state.refer )
    {
      this.$store.commit( "SET_REFER", null );
    }
  },
  methods: {
    async checkInvalidData ()
    {
      let checkData = await this.checkCompanyData( this.user );
      if ( checkData )
      {
        if ( this.$route.name != "EditCompany" )
        {
          let url = "/edit-company";
          typeof this.$route.query.code != "undefined"
            ? ( url = url + "?code=" + this.$route.query.code )
            : null;
          this.$router.push( url ).catch( err => { } );
        }
      } else if ( this.user.consent == 0 )
      {
        setTimeout( async () =>
        {
          let getUser = await this.getUserById( this.token );
          this.$store.commit( "SET_USER", getUser.data.obj );
          if ( getUser.data.obj.consent == 0 )
          {
            let url = "/edit-privacy";
            typeof this.$route.query.code != "undefined"
              ? ( url = url + "?code=" + this.$route.query.code )
              : null;
            this.$router.push( url ).catch( err => { } );
          } else
          {
            if (
              this.user.recommendStatus == 1 &&
              this.$route.name != "SendSuccess"
            )
            {
              this.checkSkip();
            }
            if (
              typeof this.$route.query.code != "undefined" &&
              this.$route.name != "feedback user"
            )
            {
              this.checkReferCode( this.$route.query.code );
            }
          }
        }, 1000 );
      } else
      {
        if (
          this.user.recommendStatus == 1 &&
          this.$route.name != "SendSuccess"
        )
        {
          // console.log("checkSkip");
          this.checkSkip();
        }
        if (
          typeof this.$route.query.code != "undefined" &&
          this.$route.name != "feedback user"
        )
        {
          this.checkReferCode( this.$route.query.code );
        }
      }
    },
    referCodeUrl ( url )
    {
      let path = url;
      typeof this.$route.query.code != "undefined"
        ? ( path = path + "?code=" + this.$route.query.code )
        : null;
      return path;
    },
    async checkReferCode ( code )
    {
      let res = await this.getReferCode( code );
      //console.log("checkReferCode", res);
      if ( res.data.success )
      {
        this.project = res.data.obj;
        //console.log(res.data.obj.id);
        let check = await this.apiCheckJoin( this.token, res.data.obj.id );
        if ( check.data.success )
        {
          this.modalProject = true;
        } else
        {
          if ( check.data.data == "You Have Joined" )
          {
            this.$alert( `คุณได้เข้าร่วมโครงการนี้แล้ว`, {
              confirmButtonText: "OK",
              type: "warning"
            } );
          } else if ( check.data.data == "Refer code time out." )
          {
            this.$alert( `ปิดรับสมัครโครงการแล้ว`, {
              confirmButtonText: "OK",
              type: "warning"
            } );
          } else
          {
            this.alertCatchError( check.data.data );
          }
          this.$router.push( this.$route.path );
        }
      } else
      {
        if ( res.data.data == "Refer code time out." )
        {
          this.$router.push( this.$route.path ).catch( err => { } );
        } else
        {
          //this.alertCatchError(res.data.data);
          this.$router.push( this.$route.path ).catch( err => { } );
        }
        this.resData = res.data;
        this.dialogCode = true;
      }
    },
    async joinRef ( code )
    {
      let res = await this.joinProjectByRefer( this.token, code );
      if ( res.data.success )
      {
        this.modalProject = false;
        this.successDialog = true;
      } else
      {
        if ( res.data.data == "You Have Joined" )
        {
          this.$alert( `คุณได้เข้าร่วมโครงการนี้แล้ว`, {
            confirmButtonText: "OK",
            type: "warning"
          } );
        } else if ( res.data.data == "Refer code time out." )
        {
          this.$alert( `ปิดรับสมัครโครงการแล้ว`, {
            confirmButtonText: "OK",
            type: "warning"
          } );
        } else
        {
          this.alertCatchError( res.data.data );
        }
        this.$router.push( this.$route.path );
      }
      this.modalProject = false;
    },
    async checkSkip ()
    {
      HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
      HTTP.defaults.headers.common.Authorization = `Bearer ${ this.token }`;
      let res = await HTTP.get( "check/skip" ).catch( e =>
      {
        console.log( e );
      } );
      if ( typeof res.data.success != "undefined" && res.data.success )
      {
        this.complacencyStatus = res.data.obj.status;
        if ( res.data.obj.status == 0 )
        {
          if ( typeof this.$route.query.code != "undefined" )
          {
            this.$router
              .push( "/feedback?code=" + this.$route.query.code )
              .catch( err => { } );
          } else
          {
            this.$router.push( "/feedback" ).catch( err => { } );
          }
        }
      } else
      {
        if ( this.user.recommendStatus == 1 )
        {
          if ( typeof this.$route.query.code != "undefined" )
          {
            this.$router
              .push( "/feedback?code=" + this.$route.query.code )
              .catch( err => { } );
          } else
          {
            this.$router.push( "/feedback" ).catch( err => { } );
          }
        }
      }
      if (
        typeof this.$route.query.code != "undefined" &&
        this.$route.name == "feedback user"
      )
      {
        this.checkReferCode( this.$route.query.code );
      }
    },
    open ()
    {
      this.$confirm( "คุณต้องการยืนยันการประเมินใหม่?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning"
      } )
        .then( () =>
        {
          this.$message( {
            type: "success",
            message: "Delete completed"
          } );
        } )
        .catch( () =>
        {
          this.$message( {
            type: "info",
            message: "Delete canceled"
          } );
        } );
    },
    async signout ()
    {
      this.fullLoading = true;
      setTimeout( () =>
      {
        window.localStorage.eram.clear();
      }, 200 );
      // window.localStorage.eram.clear();
      this.$store.commit( "SIGNOUT" );
      window.localStorage.removeItem( "eram" );
      // window.localStorage.clear();
      delete HTTP.defaults.headers.common.Authorization;
      // window.location.href = "/";
      //this.$router.push("/");
      setTimeout( () =>
      {
        // window.location.href = "/";
        let res = AuthService.revokeAccessToken();
        //AuthService.signoutRedirect();
        //  this.$router.go();
        this.$router.push( "/" );
      }, 250 );
    }
  }
};
</script>

<style scoped>
.main-slot {
  min-height: calc(100vh - 99px);
}

/* @media (min-width: 768px) and (max-width: 1599px) {
  .main-slot {
    min-height: calc(100vh - 158px) !important;
  }
}

@media (min-width: 1600px) {
  .main-slot {
    min-height: calc(100vh - 160px) !important;
  }
} */
.footer {
  border-top: 3px solid #3598ff;
  background-color: #07509c;
  font-size: 18px;
  color: #fff;
  padding: 7.5px 10px;
}
</style>
